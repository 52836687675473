import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import Seo from '../components/seo'
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper'
import Accordion from '../components/accordion/Accordion'

// images

const HeadingTwo = styled.h2`
  margin-top: 64px;
  margin-bottom: 64px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 30px solid var(--green);
  border-top: 30px solid var(--green);
`

const ColorBlack = { color: `black` }

const FAQPage = () => (
  <Layout>
    <Seo
      title="FAQ"
      description="Commonly asked questions and answers about restoration and repair services, including water repair, mold, and fire or smoke damage."
      canonical="https://ecotechrestoration.ca/FAQ/"
    />

    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: FAQTest }}
    />

    {/* Heros */}
    <div className="heroMobile">
      {/* <StaticImage
        src="../images/heros/faq.jpg"
        maxHeight={600}
        quality={95}
        formats={['AUTO', 'WEBP', 'AVIF']}
        alt="FAQ Ecotech Restorations"
      /> */}

      <StaticImage
        src="../images/heros/faq.jpg"
        // maxHeight={600}
        quality={95}
        layout="fullWidth"
        alt="FAQ Ecotech Restorations"
      />
    </div>

    <SiteWidthWrapper>
      <ul>
        <li>Residential</li>
        <li>
          <Link style={ColorBlack} to="/FAQ/#residentialSmoke">
            skip to Smoke &amp; fire FAQ
          </Link>
        </li>
        <li>
          <Link style={ColorBlack} to="/FAQ/#resMoldQuestions">
            skip to Mold FAQ
          </Link>
        </li>
        <li>
          <Link style={ColorBlack} to="/FAQ/#resGeneralQuestions">
            skip to General FAQ
          </Link>
        </li>
      </ul>

      <HeadingTwo id="residentialQuestions">Residential</HeadingTwo>

      {/* EXAMPLE FOR SINGLE  */}
      {/* <Accordion
			title=""
			content="" /> */}
      {/* END EXAMPLE FOR SINGLE */}

      {/* EXAMPLE FOR MULTI */}
      {/* <Accordion
			title="Can I purchase items again?"
			content="
			<p>add txt here</p>
			</br>
			<p>add txt here</p>
			</br>
			<p>add txt here</p>
      {/* END EXAMPLE FOR MULTI */}

      <section>
        <Accordion
          title="How long could it take my property to dry after water or flooding damage?"
          content="After water damage, drying of your home is ascertained by analyzing a variety of factors such as location, duration and origin of water; types of construction materials in the property; weather factors; and start timing of emergency restoration services. Through continuous monitoring and evaluating of the drying stage, our trained technicians can determine when the drying is finished. For example, the carpet may seem dry, however underlay as well as subfloors could still be wet. The drying process of your property on average might take between three and five days or more, depending on the situation."
        />

        <Accordion
          title="Will I require general contractors for reconstruction of my home due to water damage?"
          content="Various aspects of the restoration process could require the services of subcontractors such as flooring/carpet; plumbing; electrical; framing/finish; drywall/painting; roofing; framing/finish carpentry; installation of cabinets, doors, windows; post-restoration cleanup, etc. Ecotech also offers/provides full reconstruction services."
        />

        <Accordion
          title="How long can the water damage restoration take?"
          content="The restoration process may take several phases, depending on the extent of the project and the damage. A complete evaluation plus assessment of all damage will be prepared prior to the estimate of a completion date. Throughout this process, your Ecotech Project Manager will communicate details to you and your Insurance company regularly and effectively, to smooth/ease the process."
        />

        <Accordion
          title="Will I be required to leave my home during the restoration process?"
          content="The extent of the project will determine if the contents of your property will need to be temporarily moved. If it is required, our team will appropriately secure, cover and safeguard the contents. Any work-related dust or dirt that occurs as a result of the construction work would be attended to during the cleaning after the project is completed. For large restoration jobs, it is frequently required to move contents to protect them. Only a secure heated facility will be utilized. This will allow space for reconstruction as well. Ecotech personnel will safeguard your possessions and handle them with care. Our technicians take detailed record of all your possessions being moved to storage."
        />

        <Accordion
          title="During the restoration process, will it be necessary for my belongings to be relocated?"
          content="The restoration process may take several phases, depending on the extent of the project and the damage. A complete evaluation plus assessment of all damage will be prepared prior to the estimate of a completion date. Throughout this process, your Ecotech Project Manager will communicate details to you and your Insurance company regularly and effectively, to smooth/ease the process."
        />

        <Accordion
          title="How can I be sure my home is thoroughly dry?"
          content="The property is tested by utilizing specialized tools that analyze and ensure that the drying process has been thorough and completed."
        />

        <Accordion
          title="Will increasing the temperature or heat assist in the drying process?"
          content="Our team has been specially trained to set the correct temperature for the absolute best drying climate. Their HVAC and temperature settings should not be adjusted as it may affect the drying process."
        />

        <Accordion
          title="Why use dehumidifiers and air movers?"
          content="Water can damage flooring, carpeting, baseboards, and drywall through infiltration and cause absorption of water. Utilizing multiple movers can effectively ensure the drying process is complete, avoiding mold and other complications. The use of industry-standard dehumidifiers as well assists in the removal of moisture and preventing further damage."
        />

        <Accordion
          title="Are we responsible for monitoring the drying equipment?"
          content="Ecotech Restoration team members are trained to place and monitor all special equipment for the drying phase. The air movers and dryers should only be moved or adjusted by company representatives"
        />

        <Accordion
          title="What is the cost to run drying equipment?"
          content="According to average electricity billing rates per month, operation may cost approximately one dollar per day per piece of drying equipment. The actual rates vary depending on your electricity provider and the energy rates they charge you."
        />

        <Accordion
          title="What about sewage contamination?"
          content="After water damage occurs, hard surfaces can be cleaned and sanitized. However, affected items that cannot be sanitized will require disposal. Drywall, ceiling tiles, insulation, paneling, and other porous materials that have been directly affected by water damage should be removed, typically during an emergency service visit."
        />

        <Accordion
          title="Will my wood floors need to be replaced if they are wet and buckling?"
          content="If the wood flooring in your home has been damaged by water, it must be continually evaluated by a qualified technician during the drying process. We use our professional-grade dehumidifiers and drying systems to foster the ideal environment for wood flooring to efficiently dry and resume their original shape optimally. Drying can take three weeks or longer, depending on density of hardwood and the type of finishing used. If you have filed a claim, your insurance adjuster will work with you to make final decisions as to whether the flooring in your home needs to be replaced or refinished."
        />

        <Accordion
          title="What will happen with area rugs?"
          content="Any rugs affected by water damage will be taken for special treatment and care. They need to be carefully dried to minimize discolouration and bleeding of colours. Your rugs will be cleaned, dried, and then returned to you and your home after they have been completely dried, and the rest of your home has been completed."
        />

        <Accordion
          title="Why would specialty flooring need to be removed?"
          content="Specialty flooring that is nonporous can trap water and prevent it and any materials underneath from drying properly."
        />

        <Accordion
          title="Why would a technician leave wet carpet on the stairs?"
          content="When water damage is present in your home, carpeting on stairs is typically not removed for safety reasons. Any exposed tack strips or staples on stairs can be dangerous. There are situations when removing carpeting from stairs is necessary, however, to prevent additional damage to hardwood steps underneath, or in cases of sewage contamination. Removing the tack strips is not advised, as it may cause damage to the hardwood or sub-floor. When carpeted stairs are damp, extreme caution must be used if the tack strip is exposed. If carpeting left on the stairs is unable to be salvaged, it will be removed when the new carpeting is installed."
        />

        <Accordion
          title="How will Ecotech Restoration match my carpet pad?"
          content="A small sample of your carpet pad is brought in for a match. An identical pad will be used when available. If not available, we will provide one with the same density, thickness, and quality. In this case, it may differ in colour based on the time it was manufactured."
        />

        <Accordion
          title="What needs to be done if my ceilings are wet?"
          content="Ecotech Restoration's water damage specialists evaluate the moisture content in your ceiling to determine the severity of damage. Some of your ceiling may also need to be removed for ventilation purposes. The insulation inside the ceiling may also be a hazard if wet, and therefore may also need to be removed."
        />

        <Accordion
          title="Should windows be open to help the drying process?"
          content="It is not always recommended to open the windows in your home for the drying process, as outside weather conditions vary. Your Ecotech Restoration technician will determine if opening windows is advisable."
        />

        <Accordion
          title="Am I able to walk on the area during drying?"
          content="It is strongly recommended that foot traffic is kept minimal during the drying process. If necessary, it is recommended to wear shoes during the process for safety concerns."
        />

        <Accordion
          title="What will happen with wet furniture?"
          content="Whether your furniture can be restored is dependent on the construction and extent of the damage. Damage can be adequately assessed once your furniture has been dried. Furniture that cannot be salvaged will be documented for you, and if disposal of items is necessary, a customer release form will be required."
        />
      </section>

      <section>
        <HeadingTwo id="residentialSmoke">
          Smoke Restoration and Fire Damage
        </HeadingTwo>

        <Accordion
          title="Is it possible to clean up smoke and fire damage myself?"
          content="We strongly suggest that you don't attempt &#34;do-it-yourself&#34; cleaning methods after smoke and fire damage in your home without consulting your Ecotech Restoration technician. Testing, utilizing the correct cleaning agents for specific items, and mixing deodourizing agents with some cleaning solutions in the correct dilution ratios are just some ways an expert fire restoration technician acquires controlled and quality results. Professional products and cleaning systems used by experienced and trained technicians give homeowners the best chance for successful restoration of your belongings and property."
        />

        <Accordion
          title="What are some of the safety concerns after fire and smoke damage?"
          content="Ecotech Restoration's top priority is occupant and worker safety. The initial inspection identifies and addresses safety hazards, which includes debris removal, checking air quality, electrical problems, slipping and tripping hazards, etc. Any electrical appliances that are burnt are separated for disposal, and possibly damaged electrical appliances are unplugged and marked for evaluation for safe operation. It may be required for electrical power to be turned off until an evaluation has been completed by a licensed electrician."
        />

        <Accordion
          title="After a fire in my home, do I need general contractors for restoration?"
          content="Some aspects of the restoration process may require the services of general contractors. These include painting/drywall, electrical, plumbing, roofing, finishing/framing carpentry, carpeting/flooring, door installation, windows, cabinets, cleaning post-construction, etc. Ecotech Restoration offers complete reconstruction services."
        />

        <Accordion
          title="After a fire in my home, how long will it take to repair or reconstruct my home?"
          content="The restoration process may require several phases depending on the damage to your home and its contents. Before a completion date can be estimated or established, a thorough evaluation and assessment of the damage will need to be completed. Your Ecotech Restoration Project Manager will communicate throughout the process to you and your Insurance Provider to make the process seamless and effective."
        />

        <Accordion
          title="Should I move out during the restoration?"
          content="Ultimately this is your decision, depending on the extent of the smoke or fire damage. Some guidance may be provided by your insurance company. You may want to consider the safety concerns, odours, electricity, and any disruptions resulted by the work, equipment, and noise that may be necessary to complete the restoration of your home. If you decide to vacate your premises during the restoration, consider forwarding mail to your temporary residence, halting deliveries, alerting your utility and cable companies, etc., as you may temporarily suspend those services."
        />

        <Accordion
          title="Will my belongings need to be moved from the premises during the restoration process?"
          content="It may be advised to move all your belongings to a secure location for cleaning and storage, depending on the extent of the smoke or fire damage, to make room for construction or restoration. Ecotech Restoration will work with you and your insurance representative to seamlessly manage this process."
        />
      </section>

      <section>
        <HeadingTwo id="resMoldQuestions">Mold Removal</HeadingTwo>

        <Accordion
          title="What is mould?"
          content="Mould is a fungus that is found both outdoors and indoors. It is not known how many species of mold exist, but the estimates range from tens of thousands, to possibly more than three hundred thousand. Mold reproduces and spreads by creating spores. These spores can even survive conditions that are too hot, sunny, dry, wet, or cold allowing mold to grow. When spores are then released, they can be carried by water or air to new locations and cause more mold growth."
        />

        <Accordion
          title="What causes mold in my home?"
          content="Any excess moisture caused by leaks or flooding in typically damp environments such as kitchens, bathrooms, or laundry rooms, create the perfect breeding ground for mold or mildew. To reduce the risk and control mold growth in those areas, you must maintain your plumbing and carefully monitor appliances that utilize large quantities of water, such as washing machines and dishwashers. Some small amounts of indoor mold are usually harmless and unavoidable. However, mold spores in damp environments reproduce and cause more difficult and serious mold problems that can be difficult to remove."
        />

        <Accordion
          title="How does mold spread?"
          content="Mold needs a damp environment and an organic food source, such as cellulose, to grow and reproduce. Cellulose is found in a variety of building materials, such as drywall, wood, ceiling tiles, fabrics, and carpeting. It can also grow in home HVAC systems, foundations, gutters, the roof, and other areas where the environment encourages growth of mold."
        />

        <Accordion
          title="What are the warning signs of mold?"
          content="Common signs of mold growth are dark spots on your ceiling, walls, or floors. Mildew and musty odours are also often signs that mold may be present in your home. Allergy and respiratory symptoms such as sneezing, coughing, wheezing, and trouble breathing are common indicators of mold growth. Condensation on glass and metal surfaces due to high humidity levels in the home are also a mold warning sign."
        />

        <Accordion
          title="What is that smell?"
          content="If it smells like mold, it is usually mold. Mildew or mold odours are due to the presence of fungi inside pipes or walls, in hidden parts of the home, or in the air. Mildew is not as thick as mold, so if there is a particularly strong smell, you need to act very fast to prevent further growth if it is mold."
        />

        <Accordion
          title="Are there some safety concerns if mold is present in my home?"
          content="Health risks associated with containing mold in your home include skin irritation, allergy-like respiratory symptoms, flu and cold symptoms, nausea, headaches, and mold-induced asthma. If you know the causes of mold growth before it occurs, you can avoid the damage to both your home and your health."
        />

        <Accordion
          title="What is black mold?"
          content="Black mold (Statchybotrys chartarum) grows in conditions where there are excessive amounts of moisture over extended periods of time and is greenish-black in colour. Excessive humidity, water damage, condensation, and water leaks in small damp places (such as bathrooms) create the perfect breeding grounds for black mold. This type of mold releases micro-toxins, which are a noxious substance that causes serious health issues for both humans and pets."
        />

        <Accordion
          title="What is the difference between mildew and mold?"
          content="Mold typically has a fuzzy appearance and can appear in a variety of colours including black, grey, brown, green, blue, yellow, and white. Mold will certainly cause structural damage and can cause several health issues. Mildew is a surface level fungus that can easily develop on books, cardboard, and some fabrics. It typically grows in a flat pattern that may appear powdery. Mildew typically begins white and can later turn to a brown, yellow, or black colour. It is typically treated with store-bought cleaners and a scrub brush."
        />

        <Accordion
          title="Should I call I professional, or can I remove mold myself?"
          content="If mold is a small patch on a wall, it can often be removed without professional help, however if mold issues continue after you have cleaned the area and facilitated preventative measures, it could be time to call for help. Mold may be a serious issue. For large mold issues or toxic black mold problems, call in the professionals at Ecotech Restoration for quality and safe mold removal and clean up. Our technicians are professionals trained in comprehensive mold remediation. They employ the most effective techniques to safely solve any mold issues. Our teams regularly handle both small and large spaces affected by mold, commercial and residential."
        />

        <Accordion
          title="How long does mold removal take in homes?"
          content="he mold removal process may require several phases, depending on the scope of the project and the amount of mold and damage in your home. A thorough assessment and evaluation of all damage needs to be completed before we can estimate a completion date. Your Ecotech Restoration Project Manager will communicate project details to you clearly to make the removal process seamless.
			"
        />
      </section>

      <section>
        <HeadingTwo id="resGeneralQuestions">
          General Restoration Service Information
        </HeadingTwo>

        <Accordion
          title="How quickly can Ecotech Restoration respond to the emergency situations in homes?"
          content="Ecotech Restoration is available to receive your call 7 days a week, 24 hours a day, 365 days a year. A representative will be available to arrive at your home for an initial evaluation within 2 to 4 hours of receiving the call, depending on the situation and conditions."
        />

        <Accordion
          title="Is Ecotech Restoration bonded/licensed/insured?"
          content="All Ecotech Restoration's teams are licensed, bonded, and insured. You can trust that any of our technicians entering your home will be trained, compassionate, trustworthy, and courteous."
        />

        <Accordion
          title="What does pre-loss condition mean?"
          content="Some damage to your property and home may have existed prior to the loss. This damage will not be included in the scope of the repair work. Our responsibility is to return your property or home to how it was prior to the damage that occurred, utilizing materials of similar quality and kind. In other words, the pre-loss condition. However, at your request we can provide an estimate for any additional projects you would like our team to undertake. The additional work is deemed &#34;non-insured work&#34;"
        />

        <Accordion
          title="What would be considered non-insured work?"
          content="Construction projects that are unrelated to your loss are considered non-insured work. If you request, we can provide a separate estimate for any non-insured construction projects. This work will require additional authorizations and payment terms. If the non-insured work delays the completion of insured work, then the situation must first be fully discussed with your Insurance Adjuster and the Ecotech Restoration Project Manager."
        />

        <Accordion
          title="What is a by-law or code upgrade?"
          content="A by-law or code upgrade is an upgrade made when your home's pre-loss conditions no longer meet the legal building codes or by-laws. Your insurance company may recognize by-law upgrades in the estimation process. Any questions regarding those upgrades should be directed to your Insurance Adjuster."
        />

        <Accordion
          title="Are permits required for restoration work?"
          content="When required, your Ecotech Restoration Project Manager will acquire the appropriate permit(s) on your behalf, while identifying our company as the general contractor. Applicable permit fees are included in the estimates and may be covered by your insurance company."
        />

        <Accordion
          title="Am I required to be home all the time during restoration processes?"
          content="No, you do not have to worry about being required to be home all the time during the restoration process. For site access, you may provide a key to your Ecotech Restoration Project Manager or install a private-coded lock box on the property or home."
        />

        <Accordion
          title="Do I need general contractors?"
          content="Various aspects of the restoration process could require the services of subcontractors such as flooring/carpet; plumbing; electrical; framing/finish; drywall/painting; roofing; framing/finish carpentry; installation of cabinets, doors, windows; post-restoration cleanup, etc. Ecotech Restoration also offers full reconstruction services."
        />

        <Accordion
          title="How long will the restoration project take?"
          content="The length depends on the amount of damage to your home and contents, and the scope of the project. It may require several phases before its completion. A thorough assessment and evaluation of all damage needs to be completed before we can estimate a completion date. Your Ecotech Restoration Project Manager will communicate project details to you clearly to make the removal process seamless."
        />

        <Accordion
          title="Will I need to move out of my home during the restoration?"
          content="Ultimately this is your decision, depending on the extent of the damage. Some guidance may be provided by your insurance company. You may want to consider the safety concerns, odours, electricity, and any disruptions resulted by the equipment, and noise that may be necessary to complete the restoration of your home. If you decide to vacate your premises during the restoration, consider forwarding mail to your temporary residence, halting deliveries, alerting your utility and cable companies, etc., as you may temporarily suspend those services."
        />

        <Accordion
          title="Do the contents of my home need to be moved from the premises?"
          content="The contents and belongings of your home may remain on location, depending on the scope of work. We will take all appropriate measures to securely protect and cover the contents. Any construction related dirt or dust occurring as a result of work will be addressed in post-construction cleaning. For larger restoration projects, it is advised to move all affected contents to a secured climate-controlled facility, to make room for construction and protect them. Our technicians are trained and will treat your contents with care. We work with you and the Insurance Claims Representative to manage the process efficiently."
        />

        <Accordion
          title="What about special items, such as artwork, china, heirlooms, etc."
          content="High-value items may require restoration by specialists. We can work with your insurance claim representative to help you identify those items and locate qualified restorers."
        />

        <Accordion
          title="Will I be able to access all my belongings in storage?"
          content="With advance notice, we can schedule the time for an Ecotech Restoration Technician to meet with you and provide access to requested items. Service charges may apply"
        />

        <Accordion
          title="How can I keep track of my non-restorable items?"
          content="It is recommended that you make a list of items (including food related items) that are deemed non-restorable. A form for this purpose may be acquired from your insurance company. Ensure to make a copy for your insurance company while keeping one for yourself."
        />

        <Accordion
          title="What items should I be keeping in my possession?"
          content="We recommend that medications, pets, cash, valuable jewellery, chequebooks, stamp/coin collections, personal documents, guns / ammunition, valuable artworks, gasoline cans, and flammables be kept in your possession."
        />

        <Accordion
          title="Who is responsible for paying for the services?"
          content="The property owner is responsible for all payment and will need to sign forms authorizing payments for restoration services. If it is an insurance claim, Ecotech Restoration typically collects only the deductible or copayment amount from you and we bill the balance to your insurance provider as a service for you. Your mortgage company may possibly be included as a payee from your insurance company if you have a large loss, and you may be required to obtain a signature from them as well. If you make the decision not to file a claim, or the claim is not covered by your insurance, then you will be expected to pay in full. Payment schedules may be agreed on prior to the beginning of any non-insured work."
        />
      </section>
    </SiteWidthWrapper>
  </Layout>
)

export default FAQPage

const FAQTest = `
{
	"@context": "https://schema.org",
	"@type": "FAQPage",
	"mainEntity": [{
			"@type": "Question",
			"name": "How long could it take my property to dry after water or flooding damage?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "After water damage, drying of your home is ascertained by analyzing a variety of factors such as location, duration and origin of water; types of construction materials in the property; weather factors; and start timing of emergency restoration services. Through continuous monitoring and evaluating of the drying stage, our trained technicians can determine when the drying is finished. For example, the carpet may seem dry, however underlay as well as subfloors could still be wet. The drying process of your property on average might take between three and five days or more, depending on the situation."
			}
		},
		{
			"@type": "Question",
			"name": "Will I require general contractors for reconstruction of my home due to water damage?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Various aspects of the restoration process could require the services of subcontractors such as flooring/carpet; plumbing; electrical; framing/finish; drywall/painting; roofing; framing/finish carpentry; installation of cabinets, doors, windows; post-restoration cleanup, etc. Ecotech also offers/provides full reconstruction services."
			}
		},
		{
			"@type": "Question",
			"name": "How long can the water damage restoration take?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The restoration process may take several phases, depending on the extent of the project and the damage. A complete evaluation plus assessment of all damage will be prepared prior to the estimate of a completion date. Throughout this process, your Ecotech Project Manager will communicate details to you and your Insurance company regularly and effectively, to smooth/ease the process."
			}
		},
		{
			"@type": "Question",
			"name": "Will I be required to leave my home during the restoration process?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The extent of damage will determine this and your insurance adjuster can assist you with this question. It may be necessary in some circumstances, but you may decide on your own that you would prefer this. Some safety aspects to consider would be odours, electricity, and disruption as a result of the work and noise that may occur to complete the reconstruction process of your property. If you decide to vacate your premises during the restoration process, consider forwarding your mail; halting your newspaper and deliveries; notifying the utility company, cable company, etc., regarding the temporary suspension during the restoration period."
			}
		},
		{
			"@type": "Question",
			"name": "During the restoration process, will it be necessary for my belongings to be relocated?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The extent of the project will determine if the contents of your property will need to be temporarily moved. If it is required, our team will appropriately secure, cover and safeguard the contents. Any work-related dust or dirt that occurs as a result of the construction work would be attended to during the cleaning after the project is completed. For large restoration jobs, it is frequently required to move contents to protect them. Only a secure heated facility will be utilized. This will allow space for reconstruction as well. Ecotech personnel will safeguard your possessions and handle them with care. Our technicians take detailed record of all your possessions being moved to storage."
			}
		},
		{
			"@type": "Question",
			"name": "How can I be sure my home is thoroughly dry?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The property is tested by utilizing specialized tools that analyze and ensure that the drying process has been thorough and completed."
			}
		},
		{
			"@type": "Question",
			"name": "Will increasing the temperature or heat assist in the drying process?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Our team has been specially trained to set the correct temperature for the absolute best drying climate. Their HVAC and temperature settings should not be adjusted as it may affect the drying process."
			}
		},
		{
			"@type": "Question",
			"name": "Why use dehumidifiers and air movers?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Water can damage flooring, carpeting, baseboards, and drywall through infiltration and cause absorption of water. Utilizing multiple movers can effectively ensure the drying process is complete, avoiding mold and other complications. The use of industry-standard dehumidifiers as well assists in the removal of moisture and preventing further damage."
			}
		},
		{
			"@type": "Question",
			"name": "Are we responsible for monitoring the drying equipment?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Ecotech Restoration team members are trained to place and monitor all special equipment for the drying phase. The air movers and dryers should only be moved or adjusted by company representatives."
			}
		},
		{
			"@type": "Question",
			"name": "What is the cost to run drying equipment?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "According to average electricity billing rates per month, operation may cost approximately one dollar per day per piece of drying equipment. The actual rates vary depending on your electricity provider and the energy rates they charge you."
			}
		},
		{
			"@type": "Question",
			"name": "What about sewage contamination?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "After water damage occurs, hard surfaces can be cleaned and sanitized. However, affected items that cannot be sanitized will require disposal. Drywall, ceiling tiles, insulation, paneling, and other porous materials that have been directly affected by water damage should be removed, typically during an emergency service visit."
			}
		},
		{
			"@type": "Question",
			"name": "Will my wood floors need to be replaced if they are wet and buckling?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "If the wood flooring in your home has been damaged by water, it must be continually evaluated by a qualified technician during the drying process. We use our professional-grade dehumidifiers and drying systems to foster the ideal environment for wood flooring to efficiently dry and resume their original shape optimally. Drying can take three weeks or longer, depending on density of hardwood and the type of finishing used. If you have filed a claim, your insurance adjuster will work with you to make final decisions as to whether the flooring in your home needs to be replaced or refinished."
			}
		},
		{
			"@type": "Question",
			"name": "What will happen with area rugs?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Any rugs affected by water damage will be taken for special treatment and care. They need to be carefully dried to minimize discolouration and bleeding of colours. Your rugs will be cleaned, dried, and then returned to you and your home after they have been completely dried, and the rest of your home has been completed."
			}
		},
		{
			"@type": "Question",
			"name": "Why would specialty flooring need to be removed?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Specialty flooring that is nonporous can trap water and prevent it and any materials underneath from drying properly."
			}
		},
		{
			"@type": "Question",
			"name": "Why would a technician leave wet carpet on the stairs?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "When water damage is present in your home, carpeting on stairs is typically not removed for safety reasons. Any exposed tack strips or staples on stairs can be dangerous. There are situations when removing carpeting from stairs is necessary, however, to prevent additional damage to hardwood steps underneath, or in cases of sewage contamination. Removing the tack strips is not advised, as it may cause damage to the hardwood or sub-floor. When carpeted stairs are damp, extreme caution must be used if the tack strip is exposed. If carpeting left on the stairs is unable to be salvaged, it will be removed when the new carpeting is installed."
			}
		},
		{
			"@type": "Question",
			"name": "How will Ecotech Restoration match my carpet pad?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "A small sample of your carpet pad is brought in for a match. An identical pad will be used when available. If not available, we will provide one with the same density, thickness, and quality. In this case, it may differ in colour based on the time it was manufactured."
			}
		},
		{
			"@type": "Question",
			"name": "What needs to be done if my ceilings are wet?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Ecotech Restoration's water damage specialists evaluate the moisture content in your ceiling to determine the severity of damage. Some of your ceiling may also need to be removed for ventilation purposes. The insulation inside the ceiling may also be a hazard if wet, and therefore may also need to be removed."
			}
		},
		{
			"@type": "Question",
			"name": "Should windows be open to help the drying process?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "It is not always recommended to open the windows in your home for the drying process, as outside weather conditions vary. Your Ecotech Restoration technician will determine if opening windows is advisable."
			}
		},
		{
			"@type": "Question",
			"name": "Am I able to walk on the area during drying?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "It is strongly recommended that foot traffic is kept minimal during the drying process. If necessary, it is recommended to wear shoes during the process for safety concerns."
			}
		},
		{
			"@type": "Question",
			"name": "What will happen with wet furniture?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Whether your furniture can be restored is dependent on the construction and extent of the damage. Damage can be adequately assessed once your furniture has been dried. Furniture that cannot be salvaged will be documented for you, and if disposal of items is necessary, a customer release form will be required."
			}
		},
		{
			"@type": "Question",
			"name": "Is it possible to clean up smoke and fire damage myself?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "We strongly suggest that you don't attempt do-it-yourself cleaning methods after smoke and fire damage in your home without consulting your Ecotech Restoration technician. Testing, utilizing the correct cleaning agents for specific items, and mixing deodourizing agents with some cleaning solutions in the correct dilution ratios are just some ways an expert fire restoration technician acquires controlled and quality results. Professional products and cleaning systems used by experienced and trained technicians give homeowners the best chance for successful restoration of your belongings and property."
			}
		},
		{
			"@type": "Question",
			"name": "What are some of the safety concerns after fire and smoke damage?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Ecotech Restoration's top priority is occupant and worker safety. The initial inspection identifies and addresses safety hazards, which includes debris removal, checking air quality, electrical problems, slipping and tripping hazards, etc. Any electrical appliances that are burnt are separated for disposal, and possibly damaged electrical appliances are unplugged and marked for evaluation for safe operation. It may be required for electrical power to be turned off until an evaluation has been completed by a licensed electrician."
			}
		},
		{
			"@type": "Question",
			"name": "After a fire in my home, do I need general contractors for restoration?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Some aspects of the restoration process may require the services of general contractors. These include painting/drywall, electrical, plumbing, roofing, finishing/framing carpentry, carpeting/flooring, door installation, windows, cabinets, cleaning post-construction, etc. Ecotech Restoration offers complete reconstruction services."
			}
		},
		{
			"@type": "Question",
			"name": "After a fire in my home, how long will it take to repair or reconstruct my home?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The restoration process may require several phases depending on the damage to your home and its contents. Before a completion date can be estimated or established, a thorough evaluation and assessment of the damage will need to be completed. Your Ecotech Restoration Project Manager will communicate throughout the process to you and your Insurance Provider to make the process seamless and effective."
			}
		},
		{
			"@type": "Question",
			"name": "Should I move out during the restoration?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Ultimately this is your decision, depending on the extent of the smoke or fire damage. Some guidance may be provided by your insurance company. You may want to consider the safety concerns, odours, electricity, and any disruptions resulted by the work, equipment, and noise that may be necessary to complete the restoration of your home. If you decide to vacate your premises during the restoration, consider forwarding mail to your temporary residence, halting deliveries, alerting your utility and cable companies, etc., as you may temporarily suspend those services."
			}
		},
		{
			"@type": "Question",
			"name": "Will my belongings need to be moved from the premises during the restoration process?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "It may be advised to move all your belongings to a secure location for cleaning and storage, depending on the extent of the smoke or fire damage, to make room for construction or restoration. Ecotech Restoration will work with you and your insurance representative to seamlessly manage this process."
			}
		},
		{
			"@type": "Question",
			"name": "What is mould?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Mould is a fungus that is found both outdoors and indoors. It is not known how many species of mold exist, but the estimates range from tens of thousands, to possibly more than three hundred thousand. Mold reproduces and spreads by creating spores. These spores can even survive conditions that are too hot, sunny, dry, wet, or cold allowing mold to grow. When spores are then released, they can be carried by water or air to new locations and cause more mold growth."
			}
		},
		{
			"@type": "Question",
			"name": "What causes mold in my home?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Any excess moisture caused by leaks or flooding in typically damp environments such as kitchens, bathrooms, or laundry rooms, create the perfect breeding ground for mold or mildew. To reduce the risk and control mold growth in those areas, you must maintain your plumbing and carefully monitor appliances that utilize large quantities of water, such as washing machines and dishwashers. Some small amounts of indoor mold are usually harmless and unavoidable. However, mold spores in damp environments reproduce and cause more difficult and serious mold problems that can be difficult to remove."
			}
		},
		{
			"@type": "Question",
			"name": "How does mold spread?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Mold needs a damp environment and an organic food source, such as cellulose, to grow and reproduce. Cellulose is found in a variety of building materials, such as drywall, wood, ceiling tiles, fabrics, and carpeting. It can also grow in home HVAC systems, foundations, gutters, the roof, and other areas where the environment encourages growth of mold."
			}
		},
		{
			"@type": "Question",
			"name": "What are the warning signs of mold?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Common signs of mold growth are dark spots on your ceiling, walls, or floors. Mildew and musty odours are also often signs that mold may be present in your home. Allergy and respiratory symptoms such as sneezing, coughing, wheezing, and trouble breathing are common indicators of mold growth. Condensation on glass and metal surfaces due to high humidity levels in the home are also a mold warning sign."
			}
		},
		{
			"@type": "Question",
			"name": "What is that smell?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "If it smells like mold, it is usually mold. Mildew or mold odours are due to the presence of fungi inside pipes or walls, in hidden parts of the home, or in the air. Mildew is not as thick as mold, so if there is a particularly strong smell, you need to act very fast to prevent further growth if it is mold."
			}
		},
		{
			"@type": "Question",
			"name": "Are there some safety concerns if mold is present in my home?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Health risks associated with containing mold in your home include skin irritation, allergy-like respiratory symptoms, flu and cold symptoms, nausea, headaches, and mold-induced asthma. If you know the causes of mold growth before it occurs, you can avoid the damage to both your home and your health"
			}
		},
		{
			"@type": "Question",
			"name": "What is black mold?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Black mold (Statchybotrys chartarum) grows in conditions where there are excessive amounts of moisture over extended periods of time and is greenish-black in colour. Excessive humidity, water damage, condensation, and water leaks in small damp places (such as bathrooms) create the perfect breeding grounds for black mold. This type of mold releases micro-toxins, which are a noxious substance that causes serious health issues for both humans and pets"
			}
		},
		{
			"@type": "Question",
			"name": "What is the difference between mildew and mold?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Mold typically has a fuzzy appearance and can appear in a variety of colours including black, grey, brown, green, blue, yellow, and white. Mold will certainly cause structural damage and can cause several health issues. Mildew is a surface level fungus that can easily develop on books, cardboard, and some fabrics. It typically grows in a flat pattern that may appear powdery. Mildew typically begins white and can later turn to a brown, yellow, or black colour. It is typically treated with store-bought cleaners and a scrub brush."
			}
		},
		{
			"@type": "Question",
			"name": "Should I call I professional, or can I remove mold myself?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "If mold is a small patch on a wall, it can often be removed without professional help, however if mold issues continue after you have cleaned the area and facilitated preventative measures, it could be time to call for help. Mold may be a serious issue. For large mold issues or toxic black mold problems, call in the professionals at Ecotech Restoration for quality and safe mold removal and clean up. Our technicians are professionals trained in comprehensive mold remediation. They employ the most effective techniques to safely solve any mold issues. Our teams regularly handle both small and large spaces affected by mold, commercial and residential."
			}
		},
		{
			"@type": "Question",
			"name": "How long does mold removal take in homes?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The mold removal process may require several phases, depending on the scope of the project and the amount of mold and damage in your home. A thorough assessment and evaluation of all damage needs to be completed before we can estimate a completion date. Your Ecotech Restoration Project Manager will communicate project details to you clearly to make the removal process seamless."
			}
		},
		{
			"@type": "Question",
			"name": "How quickly can Ecotech Restoration respond to the emergency situations in homes?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Ecotech Restoration is available to receive your call 7 days a week, 24 hours a day, 365 days a year. A representative will be available to arrive at your home for an initial evaluation within 2 to 4 hours of receiving the call, depending on the situation and conditions."
			}
		},
		{
			"@type": "Question",
			"name": "Is Ecotech Restoration bonded/licensed/insured?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "All Ecotech Restoration's teams are licensed, bonded, and insured. You can trust that any of our technicians entering your home will be trained, compassionate, trustworthy, and courteous."
			}
		},
		{
			"@type": "Question",
			"name": "What does pre-loss condition mean?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Some damage to your property and home may have existed prior to the loss. This damage will not be included in the scope of the repair work. Our responsibility is to return your property or home to how it was prior to the damage that occurred, utilizing materials of similar quality and kind. In other words, the pre-loss condition. However, at your request we can provide an estimate for any additional projects you would like our team to undertake. The additional work is deemed non-insured work."
			}
		},
		{
			"@type": "Question",
			"name": "What would be considered non-insured work?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Construction projects that are unrelated to your loss are considered non-insured work. If you request, we can provide a separate estimate for any non-insured construction projects. This work will require additional authorizations and payment terms. If the non-insured work delays the completion of insured work, then the situation must first be fully discussed with your Insurance Adjuster and the Ecotech Restoration Project Manager."
			}
		},
		{
			"@type": "Question",
			"name": "What is a by-law or code upgrade?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "A by-law or code upgrade is an upgrade made when your home's pre-loss conditions no longer meet the legal building codes or by-laws. Your insurance company may recognize by-law upgrades in the estimation process. Any questions regarding those upgrades should be directed to your Insurance Adjuster."
			}
		},
		{
			"@type": "Question",
			"name": "Are permits required for restoration work?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "When required, your Ecotech Restoration Project Manager will acquire the appropriate permit(s) on your behalf, while identifying our company as the general contractor. Applicable permit fees are included in the estimates and may be covered by your insurance company."
			}
		},
		{
			"@type": "Question",
			"name": "Am I required to be home all the time during restoration processes?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "No, you do not have to worry about being required to be home all the time during the restoration process. For site access, you may provide a key to your Ecotech Restoration Project Manager or install a private-coded lock box on the property or home."
			}
		},
		{
			"@type": "Question",
			"name": "Do I need general contractors?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Various aspects of the restoration process could require the services of subcontractors such as flooring/carpet; plumbing; electrical; framing/finish; drywall/painting; roofing; framing/finish carpentry; installation of cabinets, doors, windows; post-restoration cleanup, etc. Ecotech Restoration also offers full reconstruction services."
			}
		},
		{
			"@type": "Question",
			"name": "How long will the restoration project take?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The length depends on the amount of damage to your home and contents, and the scope of the project. It may require several phases before its completion. A thorough assessment and evaluation of all damage needs to be completed before we can estimate a completion date. Your Ecotech Restoration Project Manager will communicate project details to you clearly to make the removal process seamless."
			}
		},
		{
			"@type": "Question",
			"name": "Will I need to move out of my home during the restoration?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Ultimately this is your decision, depending on the extent of the damage. Some guidance may be provided by your insurance company. You may want to consider the safety concerns, odours, electricity, and any disruptions resulted by the equipment, and noise that may be necessary to complete the restoration of your home. If you decide to vacate your premises during the restoration, consider forwarding mail to your temporary residence, halting deliveries, alerting your utility and cable companies, etc., as you may temporarily suspend those services."
			}
		},
		{
			"@type": "Question",
			"name": "Do the contents of my home need to be moved from the premises?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The contents and belongings of your home may remain on location, depending on the scope of work. We will take all appropriate measures to securely protect and cover the contents. Any construction related dirt or dust occurring as a result of work will be addressed in post-construction cleaning. For larger restoration projects, it is advised to move all affected contents to a secured climate-controlled facility, to make room for construction and protect them. Our technicians are trained and will treat your contents with care. We work with you and the Insurance Claims Representative to manage the process efficiently."
			}
		},
		{
			"@type": "Question",
			"name": "What about special items, such as artwork, china, heirlooms, etc.",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "High-value items may require restoration by specialists. We can work with your insurance claim representative to help you identify those items and locate qualified restorers."
			}
		},
		{
			"@type": "Question",
			"name": "Will I be able to access all my belongings in storage?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "With advance notice, we can schedule the time for an Ecotech Restoration Technician to meet with you and provide access to requested items. Service charges may apply."
			}
		},
		{
			"@type": "Question",
			"name": "How can I keep track of my non-restorable items?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "It is recommended that you make a list of items (including food related items) that are deemed non-restorable. A form for this purpose may be acquired from your insurance company. Ensure to make a copy for your insurance company while keeping one for yourself."
			}
		},
		{
			"@type": "Question",
			"name": "What items should I be keeping in my possession?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "We recommend that medications, pets, cash, valuable jewellery, chequebooks, stamp/coin collections, personal documents, guns / ammunition, valuable artworks, gasoline cans, and flammables be kept in your possession."
			}
		},
		{
			"@type": "Question",
			"name": "Who is responsible for paying for the services?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The property owner is responsible for all payment and will need to sign forms authorizing payments for restoration services. If it is an insurance claim, Ecotech Restoration typically collects only the deductible or copayment amount from you and we bill the balance to your insurance provider as a service for you. Your mortgage company may possibly be included as a payee from your insurance company if you have a large loss, and you may be required to obtain a signature from them as well. If you make the decision not to file a claim, or the claim is not covered by your insurance, then you will be expected to pay in full. Payment schedules may be agreed on prior to the beginning of any non-insured work."
			}
		}]
}
`
